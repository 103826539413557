var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"about_tab","role":"tabpanel","aria-labelledby":"about-tab"}},[_c('div',{staticClass:"about_tab_content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"my-4 text-right"},[_c('button',{staticClass:"btn btn-pill donate",on:{"click":function($event){return _vm.$router.replace({
              query: {
                ..._vm.$route.query,
                modal: `add-eventDate`,
              },
            })}}},[_vm._v(" + "+_vm._s(_vm.$t("newEventDate"))+" ")])]),_c('Tables',{attrs:{"items":_vm.getEventItems,"no-search":"","loading":_vm.getEventItemsAreLoading,"headers":_vm.headers},scopedSlots:_vm._u([{key:"date",fn:function({ item }){return [_c('h3',{class:`badge badge-${_vm.CHECK_EXPIRED_EVENT(item).variant} py-2`}),_c('strong',[_vm._v(" "+_vm._s(_vm.formatter(new Date(item.date), { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", timeZone: "Europe/Istanbul", }))+" ")])]}},{key:"status",fn:function({ item }){return [_c('span',{class:`badge badge-${
              _vm.CHECK_EXPIRED_EVENT(item).variant
            } py-2 w-100`},[_vm._v(_vm._s(_vm.CHECK_EXPIRED_EVENT(item).text))])]}},{key:"isActive",fn:function({ item }){return [_c('router-link',{attrs:{"to":`/event-detail/${_vm.product.id}/event-date/${item.id}`}},[_c('button',{staticClass:"btn btn-pill btn-xs m-1 donate"},[_vm._v(" "+_vm._s(_vm.$t("goToDetail"))+" ")])]),_c('router-link',{attrs:{"to":`/event-detail/${_vm.product.id}/event-date/${item.id}?tab=uploadedItems`}},[_c('button',{staticClass:"btn btn-pill btn-xs m-1"},[_vm._v(" "+_vm._s(_vm.$t("uploadedItems"))+" ")])]),_c('button',{staticClass:"btn-xs btn bg-success btn-pill",on:{"click":function($event){return _vm.SET_NEW_FILE(item)}}},[_c('img',{attrs:{"src":"/images/upload.svg","width":"24","alt":"Upload"}})]),_c('router-link',{attrs:{"to":`/event-detail/${_vm.product.id}/event-date/${item.id}?modal=eventDates-${item.id}`}},[_c('button',{staticClass:"btn-xs btn bg-warning btn-pill mx-1",attrs:{"title":_vm.$t('edit')},on:{"click":function($event){return _vm.SET_NEW_FILE(item)}}},[_c('img',{attrs:{"src":"/images/edit.svg","width":"24","alt":_vm.$t('edit')}})])])]}}])}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('BoostrapPagination',{attrs:{"page":_vm.page,"pages":_vm.pages},on:{"change":_vm.SET_PAGINATION}})],1)],1)]),_c('EventDatesDetail',{attrs:{"product":_vm.product,"event-dates":_vm.getEventItems}}),_c('NewEventDates',{attrs:{"product":_vm.product,"event-dates":_vm.getEventItems}}),_c('Modal',{attrs:{"lg":"","hide-footer":""},model:{value:(_vm.showUploadEvent),callback:function ($$v) {_vm.showUploadEvent=$$v},expression:"showUploadEvent"}},[_c('AssignmentFileModal',{attrs:{"type":2,"event":_vm.activeEventDate},on:{"close":function($event){_vm.showUploadEvent = false},"refresh":function($event){_vm.showUploadEvent = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }