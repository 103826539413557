<!-- LIST OF EVENT ITEM BAĞLANACAK -->
<template>
  <div
    class="tab-pane fade show active"
    id="about_tab"
    role="tabpanel"
    aria-labelledby="about-tab"
  >
    <div class="about_tab_content">
      <!-- <div v-if="activeEventDate.id === -1" class="container"> -->
      <div class="container">
        <div class="my-4 text-right">
          <button
            @click="
              $router.replace({
                query: {
                  ...$route.query,
                  modal: `add-eventDate`,
                },
              })
            "
            class="btn btn-pill donate"
          >
            + {{ $t("newEventDate") }}
          </button>
        </div>
        <Tables
          :items="getEventItems"
          no-search
          :loading="getEventItemsAreLoading"
          :headers="headers"
        >
          <template #date="{ item }">
            <h3
              :class="`badge badge-${CHECK_EXPIRED_EVENT(item).variant} py-2`"
            ></h3>
            <strong>
              {{
                formatter(new Date(item.date), {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  timeZone: "Europe/Istanbul",
                })
              }}
            </strong>
          </template>
          <template #status="{ item }">
            <span
              :class="`badge badge-${
                CHECK_EXPIRED_EVENT(item).variant
              } py-2 w-100`"
              >{{ CHECK_EXPIRED_EVENT(item).text }}</span
            >
          </template>

          <template #isActive="{ item }">
            <router-link
              :to="`/event-detail/${product.id}/event-date/${item.id}`"
            >
              <button class="btn btn-pill btn-xs m-1 donate">
                {{ $t("goToDetail") }}
              </button>
            </router-link>
            <router-link
              :to="`/event-detail/${product.id}/event-date/${item.id}?tab=uploadedItems`"
            >
              <button class="btn btn-pill btn-xs m-1">
                {{ $t("uploadedItems") }}
              </button>
            </router-link>
            <button
              @click="SET_NEW_FILE(item)"
              class="btn-xs btn bg-success btn-pill"
            >
              <img src="/images/upload.svg" width="24" alt="Upload" />
            </button>
            <router-link
              :to="`/event-detail/${product.id}/event-date/${item.id}?modal=eventDates-${item.id}`"
            >
              <button
                @click="SET_NEW_FILE(item)"
                :title="$t('edit')"
                class="btn-xs btn bg-warning btn-pill mx-1"
              >
                <img src="/images/edit.svg" width="24" :alt="$t('edit')" />
              </button>
            </router-link>
          </template>
        </Tables>
        <div class="d-flex justify-content-center">
          <BoostrapPagination
            @change="SET_PAGINATION"
            :page="page"
            :pages="pages"
          ></BoostrapPagination>
        </div>
      </div>
      <!-- <div v-else>
        <ListOfEventDate
          @back="activeEventDate = { id: -1 }"
          :product="product"
          :event="activeEventDate"
        ></ListOfEventDate>
      </div> -->
    </div>
    <EventDatesDetail
      :product="product"
      :event-dates="getEventItems"
    ></EventDatesDetail>
    <NewEventDates
      :product="product"
      :event-dates="getEventItems"
    ></NewEventDates>
    <Modal lg hide-footer v-model="showUploadEvent">
      <AssignmentFileModal
        @close="showUploadEvent = false"
        @refresh="showUploadEvent = false"
        :type="2"
        :event="activeEventDate"
      ></AssignmentFileModal>
    </Modal>
  </div>
</template>

<script>
import { formatter, convertM2H } from "gurhan/helpers/Date";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";
import Tables from "../Tables/index.vue";
import EventDatesDetail from "./EventDatesDetail.vue";
// import ListOfEventDate from "./ListOfEventDate.vue";
import NewEventDates from "./NewEventDates.vue";
import AssignmentFileModal from "../Assignments/AssignmentFileModal.vue";
import Modal from "../Shared/Modal/index.vue";
export default {
  components: {
    Tables,
    BoostrapPagination,
    EventDatesDetail,
    //  ListOfEventDate,
    NewEventDates,
    AssignmentFileModal,
    Modal,
  },
  props: {
    product: {
      default: () => {},
    },
  },
  data() {
    return {
      activeEventDate: {
        id: -1,
      },
      page: 1,
      showUploadEvent: false,
      limit: 10,
      headers: [
        { col: 12, md: 3, lg: 3, title: this.$t("eventDate"), value: "date" },
        // { col: 12, md: 6, lg: 3, title: this.$t("eventHour"), value: "time" },
        {
          col: 12,
          md: 3,
          lg: 3,
          title: this.$t("situation"),
          value: "status",
        },
        {
          col: 12,
          md: 6,
          lg: 6,
          title: this.$t("action"),
          value: "isActive",
        },
      ],
    };
  },
  computed: {
    getEventItems() {
      const eventItems = this.$store.state.Events.eventItems.find(
        (c) => c.eventId === this.product.id && c.page === this.page
      );
      if (eventItems) return eventItems.data;
      else return [];
    },
    getEventItemsAreLoading() {
      return this.$store.state.Events.eventItemsAreLoading;
    },
    pages() {
      const eventItems = this.$store.state.Events.eventItems.find(
        (c) => c.eventId === this.product.id
      );
      if (eventItems) return Math.ceil(eventItems.count / 10);
      else return 0;
    },
  },
  methods: {
    SET_NEW_FILE(item) {
      this.activeEventDate = item;
      setTimeout(() => {
        this.showUploadEvent = true;
      }, 150);
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { ...this.$route.query, page } });
      this.GET_LIST();
    },
    CHECK_EXPIRED_EVENT(item) {
      const today = new Date().getTime();
      const eventTime = new Date(item.date).getTime() + item.time * 60000;
      const eventFinishTime =
        new Date(item.date).getTime() +
        item.time * 60000 +
        item.duration * 60000;
      if (item.isActive === 0)
        return {
          status: false,
          text: this.$t("eventCanceled"),
          variant: "danger",
        };
      else if (today > eventFinishTime)
        return {
          status: false,
          text: this.$t("eventDone"),
          variant: "success",
        };
      else if (today > eventTime)
        return {
          status: false,
          text: this.$t("eventContiuning"),
          variant: "warning",
        };
      else
        return {
          status: true,
          text: this.$t("eventWaiting"),
          variant: "info",
        };
    },
    convertM2H,
    formatter,
    GET_LIST() {
      this.$store.dispatch("GET_EVENT_ITEMS", {
        page: this.page,
        limit: 10,
        id: this.product.id,
      });
    },
  },
  mounted() {
    if (!isNaN(Number(this.$route.query.page)))
      this.page = Number(this.$route.query.page);
    this.GET_LIST();
  },
};
</script>

<style></style>
